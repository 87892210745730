import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface SettingsState {
  isExpanded: boolean;
}

interface SettingsContextProps extends SettingsState {
  updateSettings: (value: boolean) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface

const initialState: SettingsState = {
  isExpanded: true,
};

const updateSession = (value: boolean) => {
  localStorage.setItem("isExpanded", JSON.stringify(value));
};

const defaultContextValues: SettingsContextProps = {
  ...initialState,
  updateSettings: async () => {},
};

const SettingsContext =
  createContext<SettingsContextProps>(defaultContextValues);

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settingsState, setSettingsState] =
    useState<SettingsState>(initialState);

  const updateAdminPractitionerId = async (value: boolean) => {
    setSettingsState((prevState) => ({
      ...prevState, // Spread the entire previous state
      isExpanded: value,
    }));
    updateSession(value);
  };

  useEffect(() => {
    const expanded = window.localStorage.getItem("isExpanded");
    if (expanded) {
      updateSession(JSON.parse(expanded));
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        ...settingsState,
        updateSettings: updateAdminPractitionerId,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
