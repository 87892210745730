import {
  Box,
  TableBody,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import { StyledTable, StyledTableContainer } from "../MyPatients/style";

const StyledTableRow = styled(TableRow)(() => ({
  // paddingLeft: '10px',
  // verticalAlign: 'baseline',
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& th": {
    // wordBreak: 'unset'
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffcc00",
    color: "black",
    padding: "10px",
    // maxWidth: "65px",

    wordBreak: "unset",
    whiteSpace: "wrap",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    // maxWidth: "65px",

    // paddingLeft: '10px',
    // paddingRight: '10px',
    padding: "10px",
    // borderRight: '1px solid rgba(224, 224, 224, 1)',
    wordBreak: "unset",
    // backgroundColor: '#f6f8fa',
    whiteSpace: "wrap",
  },
}));
const TreatmentPlanPharmacologicalTable = ({ data }: any) => {
  return (
    <Box style={{ width: "100%", overflowX: "auto" }}>
      <StyledTableContainer>
        <StyledTable style={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow key={"tableHead"}>
              <StyledTableCell align="left">ICD Code</StyledTableCell>
              <StyledTableCell align="left">Medication Name</StyledTableCell>
              <StyledTableCell align="left">
                Medication Indication
              </StyledTableCell>
              <StyledTableCell align="left">Pregnancy Alerts</StyledTableCell>
              <StyledTableCell align="left">
                Breastfeeding Alert
              </StyledTableCell>
              <StyledTableCell align="left">Contraindications</StyledTableCell>
              <StyledTableCell align="left">Age Cautionary</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any, i: number) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell align="left">{item.code}</StyledTableCell>
                  <StyledTableCell align="left">
                    {item.pharmacotherapy}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.indication}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.fda_pregnancy_categories}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.breastfeeding_warnings}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.contraindications}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.age_warnings}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            {data.length === 0 && (
              <StyledTableRow>
                <StyledTableCell
                  align="left"
                  colSpan={6}
                  sx={{ textAlign: "center" }}
                >
                  No Data Found
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </Box>
  );
};

const TreatmentPlanNonPharmacologicalTable = ({ data }: any) => {
  return (
    <Box style={{ width: "100%", overflowX: "auto" }}>
      <StyledTableContainer>
        <StyledTable style={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow key={"tableHead"}>
              <StyledTableCell align="left">ICD Code</StyledTableCell>
              <StyledTableCell align="left">Therapy</StyledTableCell>
              <StyledTableCell align="left">Support Measures</StyledTableCell>
              <StyledTableCell align="left">
                Substance Cessation
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any, i: number) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell align="left">{item.code}</StyledTableCell>
                  <StyledTableCell align="left">
                    {item.psychotherapy}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.supportive_measures}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.substance_cessation}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            {data.length === 0 && (
              <StyledTableRow>
                <StyledTableCell
                  align="left"
                  colSpan={3}
                  sx={{ textAlign: "center" }}
                >
                  No Data Found
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </Box>
  );
};
export {
  TreatmentPlanPharmacologicalTable,
  TreatmentPlanNonPharmacologicalTable,
};
