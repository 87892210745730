import React, { ReactNode } from "react";

import Dialog from "@mui/material/Dialog";

interface CustomModalProps {
  open: boolean; // Indicates if the modal is open
  handleClose: () => void; // Function to close the modal
  size?: "small" | "medium" | "large"; // Optional size prop
  children: ReactNode; // The content to display inside the modal
}
const CustomModal: React.FC<CustomModalProps> = ({
  open,
  handleClose,
  size = "medium",
  children,
}) => {
  const getModalSize = () => {
    switch (size) {
      case "small":
        return "sm";
      case "medium":
        return "md";
      case "large":
        return "lg";
      default:
        return "md";
    }
  };

  const handleBackdropClick = (event: { stopPropagation: () => void; }) => {
    event.stopPropagation(); // Prevent closing the dialog on backdrop click
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={getModalSize()}
      onClick={handleBackdropClick}
      sx={{
        borderLeft: "5px solid red", // Change 'red' to your desired color
      }}
    >
      {children}
    </Dialog>
  );
};

export default CustomModal;
