import {
  styled,
  Table,
  TableCell,
  TableRow,
  tableCellClasses,
  TableContainer,
} from "@mui/material";

export const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "none" } },
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffcc00",
    color: "black",
    padding: "10px",
    // maxWidth: "65px",

    wordBreak: "unset",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    // maxWidth: "65px",

    // paddingLeft: '10px',
    // paddingRight: '10px',
    padding: "10px",
    // borderRight: '1px solid rgba(224, 224, 224, 1)',
    wordBreak: "unset",
    // backgroundColor: '#f6f8fa',
    whiteSpace: "nowrap",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  // paddingLeft: '10px',
  // verticalAlign: 'baseline',
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& th": {
    // wordBreak: 'unset'
  },
  "&:hover": {
    border: 0,
    backgroundColor: "#add8e687",
    cursor: "pointer",
  },
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  root: {
    width: "max-content",
  },
  borderRadius:'20px'
}));
