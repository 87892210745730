import { toast, ToastContainer } from "react-toastify";
import AppDrawer from "../../../Layout/AppDrawer";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { getData, patchData } from "../../../API/apiService";
import { serverErrorMessage } from "../../../Utils/constant";
import InputAdornment from "@mui/material/InputAdornment";

const AIDASettings = () => {
  const [apiCallLoader, setApiCallLoader] = useState<boolean>(false);
  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [chunkTiming, setChunkTiming] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getChukingTiming();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChukingTiming = async () => {
    try {
      setApiCallLoader(true);
      const APIResponse = await getData("/audioChunking");
      if (APIResponse) {
        setApiCallLoader(false);
        setChunkTiming(APIResponse.value);
      }
    } catch (error) {
      console.log(error);
      setApiCallLoader(false);
      setIsServerError(true);
    }
  };
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const APIResponse = await patchData(`/audioChunking`, {
        chunkTiming: values.timing * 1000,
      });
      if (APIResponse) {
        setLoading(false);
        toast.success("Timing Updated Successfully");
      } else {
        throw new Error(APIResponse);
      }
    } catch (e: any) {
      console.log(e);
      toast.error(e.message);
      setLoading(false);
    }
  };

  return (
    <AppDrawer>
      <ToastContainer />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <div
            style={{
              padding: "20px",
              fontSize: "small",
              borderRadius: "10px", // Adjust the border radius as needed
              display: "flex",

              gap: "10px", // Adjust the gap between icon and text as needed
            }}
          >
            <IconButton
              sx={{
                borderRadius: "50%", // Makes the button rounded
                // border: "1px solid black",
                backgroundColor: "#ffcc00",
                fontSize: "small",
              }}
            >
              <Settings sx={{ fontSize: "medium", color: "black" }} />
            </IconButton>

            <Typography sx={{ fontWeight: "bold" }}>Settings</Typography>
          </div>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {!apiCallLoader && chunkTiming && (
            <>
              {" "}
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 5 }}>
                Chunk Timing
                <Divider
                  sx={{ backgroundColor: "#ffcc00", borderBottomWidth: 2 }}
                ></Divider>
              </Typography>
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  timing: parseInt(chunkTiming) / 1000,
                }}
                validationSchema={Yup.object().shape({
                  timing: Yup.number().required("Timing is required!"),
                })}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl variant="standard">
                      <Input
                        id="standard-adornment-weight"
                        value={values.timing}
                        onChange={handleChange}
                        error={Boolean(errors.timing && touched.timing)}
                        name="timing"
                        type="number"
                        endAdornment={
                          <InputAdornment position="end">
                            Seconds
                          </InputAdornment>
                        }
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          "aria-label": "timing",
                        }}
                      />
                      {errors.timing && touched.timing && (
                        <FormHelperText error>{errors.timing}</FormHelperText>
                      )}
                      <FormHelperText id="standard-weight-helper-text">
                        Chunking Timing
                      </FormHelperText>
                    </FormControl>
                    <br></br>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      fullWidth
                      loading={loading}
                      className="custom-btn"
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#ffcc00",
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "none",
                        mt: 3,
                        "&:hover": {
                          backgroundColor: "#ffcc00",
                        },
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </form>
                )}
              </Formik>
            </>
          )}
          {isServerError && <p>{serverErrorMessage}</p>}
          {apiCallLoader && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </AppDrawer>
  );
};

export default AIDASettings;
