import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { menuListItems, AdminMenuListItem } from "../Routes/MenuListItem";
import { ArrowCircleDown, Logout } from "@mui/icons-material";
import ProfileCard from "./ProfileCard";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../customHooks/AuthContext";
import CustomModal from "../Views/CustomComponents/CustomModal";
import { commonBtnStyle } from "../style";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSettings } from "../customHooks/SettingsContext";


const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
//   open?: boolean;
// }>(({ theme, open }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   transition: theme.transitions.create("margin", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: `-${drawerWidth}px`,
//   ...(open && {
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   }),
// }));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

interface AppDrawerProps {
  children: React.ReactNode;
  // Add any other props you need here
}

const AppDrawer: React.FC<AppDrawerProps> = ({ children }) => {
  const { logout, role } = useAuth();
  const { isExpanded, updateSettings } = useSettings();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  // const [apiLoading] = useState(false);
  const location = useLocation();
  // console.log(location.pathname, apiLoading);
  const [open, setOpen] = useState<boolean>(isExpanded);
  // const [openSubMenu, setOpenSubMenu] = useState<{ [key: string]: boolean }>(
  //   {}
  // );

  const toggleDrawer = (prevState: boolean) => {
    console.log("prevState", !prevState);
    updateSettings(!prevState);
    setOpen(!open);
  };

  // const handleClick = (text: string) => {
  //   // setOpenSubMenu((prev) => ({ ...prev, [text]: !prev[text] }));
  // };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmAction = () => {
    setIsLoading(true);
    logout();
  };

  const handleCancelAction = () => {
    // Handle cancel action here
    handleCloseDialog();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ToastContainer />

      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar>
          <Typography
            sx={{
              color: "black",
              marginRight: 5,
              fontSize: "x-large",
              ...(open && { display: "none" }),
              fontFamily: "Saphile",
            }}
          >
            AIDA
          </Typography>

          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 5, ...(open && { display: "none" }) }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton> */}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={4}>
              {/* <TextField
                id="outlined"
                fullWidth
                size="small"
                placeholder="Search Patient"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "35px", // adjust the border radius as needed
                    backgroundColor: "#fafafa",
                  },
                }}
              /> */}
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {/* <NotificationsNoneIcon sx={{ color: "black" }} /> */}
            </Grid>

            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {role !== "admin" && <ProfileCard />}
            </Grid>

            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                type="submit"
                onClick={handleOpenDialog}
                endIcon={<Logout />}
                color="primary"
                className="custom-btn"
                variant="contained"
                size="small"
                sx={commonBtnStyle}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        anchor="left"
        open={open}
        sx={{
          
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: '#f6f8fa', // Set background color here
          },
        }}
      >
        <DrawerHeader>
          {/* <h3
            style={{
              color: "black ",
              marginLeft: "20%",
            }}
          >
            AIDA
          </h3> */}
          <Typography
            sx={{
              color: "black",
              // marginRight: 5,
              // marginLeft: "20%",
              fontSize: "x-large",
              fontFamily: "Saphile",
            }}
          >
            AIDA
          </Typography>

          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon sx={{ color: "black" }} />
            ) : (
              <ChevronRightIcon sx={{ color: "black" }} />
            )}
          </IconButton> */}
        </DrawerHeader>

        <List>
          {role !== "admin" &&
            menuListItems.map((item, i) => (
              <ListItem
                disablePadding
                component={NavLink}
                to={item.link}
                className={
                  ((location.pathname === "/patient-details" ||
                    location.pathname === "/new-session" ||
                    location.pathname === "/session-report" ||
                    location.pathname === "/recording-feedback" ||
                    location.pathname === "/add-patient" ||
                    location.pathname === "/session-details" || location.pathname === "/session-audio") &&
                    item.link === "/my-patient") ||
                  location.pathname === item.link
                    ? "active-side-menu"
                    : ""
                }
                key={i}
              >
                <ListItemButton
                  // onClick={() => item.subItems && handleClick(item.text)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      style: {
                        fontSize: "small",
                        color: "black",
                      },
                    }}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}

          {role === "admin" &&
            AdminMenuListItem.map((item, i) => (
              <ListItem
                disablePadding
                component={NavLink}
                to={item.link}
                className={
                  ((location.pathname === "/my-patient" ||
                    location.pathname === "/patient-details" ||
                    location.pathname === "/new-session" ||
                    location.pathname === "/session-report" ||
                    location.pathname === "/recording-feedback" ||
                    location.pathname === "/add-patient" ||
                    location.pathname === "/session-details") &&
                    item.link === "/admin/practitoner-list") ||
                  location.pathname === item.link
                    ? "active-side-menu"
                    : ""
                }
                key={i}
              >
                <ListItemButton
                  // onClick={() => item.subItems && handleClick(item.text)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      style: {
                        fontSize: "small",
                        color: "black",
                      },
                    }}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        {/* <Box
        sx={{
          flexGrow: 1, // Ensures it takes the available space
          display: 'flex',
          alignItems: 'center', // Vertically centers the icon
          justifyContent: 'flex-end', // Aligns the icon to the right side
        }}
      >
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      </Box> */}

        <Box
          sx={{
            flexGrow: 1, // Ensures it takes the available space
            display: "flex",
            alignItems: "center", // Vertically centers the icon
            justifyContent: "flex-end", // Aligns the icon to the right side
          }}
        >
          <IconButton onClick={() => toggleDrawer(open)}>
            <ArrowCircleDown
              sx={{
                color: "black",
                transform: open ? "rotate(90deg)" : "rotate(270deg)", // Rotate the icon on collapse
                transition: "transform 0.9s", // Smooth rotation transition
              }}
            />
          </IconButton>
        </Box>
      </Drawer>
      {/* import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'; */}

      {/* Body of the Drawer */}
      {/* <Main open={open} sx={{ flexGrow: 1, p: 3 }}> */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
      {/* Body of the Drawer */}

      {/* Logout Modal */}
      <CustomModal
        open={openDialog}
        handleClose={handleCloseDialog}
        size="small"
      >
        <Box
          sx={{
            borderLeft: "5px solid #ffcc00",
          }}
        >
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <img
                  src="/assets/images/Doctorpic2.png"
                  alt="Logo"
                  style={{
                    height: "15vh",
                    width: "20vh", // Adjust width as needed
                  }}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>Are you sure want to Logout?</Grid>
                  <Grid item>
                    <DialogActions>
                      <Button
                        onClick={handleCancelAction}
                        color="inherit"
                        size="small"
                        variant="outlined"
                        sx={{ color: "black", textTransform: "none" }}
                      >
                        No
                      </Button>
                      <LoadingButton
                        type="submit"
                        onClick={handleConfirmAction}
                        color="primary"
                        variant="contained"
                        size="small"
                        sx={commonBtnStyle}
                        loading={isLoading}
                      >
                        Yes
                      </LoadingButton>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default AppDrawer;
