import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { decodeJWT } from "aws-amplify/auth";

// interface ExtendInitiateAuthResponse {
//   email?: string;
//   name?: string;
//   role?: string;
//   companyName?: string;
//   cognitoUserId?: string;
//   userGroups?: string | undefined;
// }

export class CognitoManager {
  private clientId: string;
  private userPoolId: string;

  constructor(regionId: string, clientId: string, userPoolId: string) {
    AWS.config.update({ region: regionId });
    this.clientId = clientId;
    this.userPoolId = userPoolId;
  }

  /**
   *
   * @param username The username (usually an email address) of the user.
   * @param password password The password of the user.
   * @returns A Promise resolving to the authentication data if successful, otherwise an error.
   */
  public async signUpUser(username: string, password: string): Promise<any> {
    const userPool = new CognitoUserPool({
      UserPoolId: this.userPoolId,
      ClientId: this.clientId,
    });
    // const attributeList = [{ Name: "email", Value: username }];

    return new Promise((resolve, reject) => {
      userPool.signUp(username, password, [], [], (err, result) => {
        if (err) {
          reject(new Error(`[signUpUser]::${err.message}`));
          return;
        }
        resolve(result);
      });
    });
  }

  /**
   *
   * @param username The username (usually an email address) of the user.
   * @param password password The password of the user.
   * @returns A Promise resolving to the authentication data if successful, otherwise an error.
   */
  public async signUpAndSignIn(
    username: string,
    password: string
  ): Promise<any> {
    try {
      // Wait for the sign-up process to complete
      await this.signUpUser(username, password);
      // After sign-up, wait for the sign-in process
      const signInResult = await this.signInUser(username, password);
      return signInResult;
    } catch (error: any) {
      console.error(error.message);
    }
  }

  /**
   *
   * @param username The username (usually an email address) of the user.
   * @param password password The password of the user.
   * @returns A Promise resolving to the authentication data if successful, otherwise an error.
   */
  public async signInUser(username: string, password: string): Promise<any> {
    const userPool = new CognitoUserPool({
      UserPoolId: this.userPoolId,
      ClientId: this.clientId,
    });

    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          const accessToken = result.getAccessToken().getJwtToken();
          const idToken = result.getIdToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();

          // Decode the ID token to get the payload
          const payload = decodeJWT(idToken)?.payload;

          // Retrieve the user groups from the payload
          const userGroups: any = payload["cognito:groups"];
          const userGroup = userGroups ? userGroups[0] : "";
          const cognitoUserId = payload["cognito:username"];

          resolve({
            accessToken,
            idToken,
            refreshToken,
            userGroup,
            cognitoUserId,
          });
        },
        onFailure: (err) => reject(new Error(`[signInUser]::${err.message}`)),
      });
    });
  }

  // public async sendVerificationCode(
  //   username: string,
  //   password: string
  // ): Promise<any> {
  //   const userPool = new CognitoUserPool({
  //     UserPoolId: this.userPoolId,
  //     ClientId: this.clientId,
  //   });

  //   const userData = { Username: username, Pool: userPool };
  //   const cognitoUser = new CognitoUser(userData);

  //   return new Promise((resolve, reject) => {
  //     cognitoUser.resendConfirmationCode((err, result) => {
  //       if (err) {
  //         reject(new Error(`[sendVerificationCode]::${err.message}`));
  //         return;
  //       }
  //       resolve(result);
  //     });
  //   });
  // }

  // public async getUserGroups(accessToken: string): Promise<string[]> {
  //   try {
  //     const cognitoISP = new AWS.CognitoIdentityServiceProvider();
  //     const params = { AccessToken: accessToken };
  //     const userResponse = await cognitoISP.getUser(params).promise();
  //     const groupsAttr = userResponse.UserAttributes?.find(
  //       (attr) => attr.Name === "cognito:groups"
  //     );
  //     return groupsAttr ? groupsAttr.Value?.split(",") || [] : [];
  //   } catch (error) {
  //     console.error("Error getting user groups", error);
  //     throw error;
  //   }
  // }

  /**
   * Changes the user's password in AWS Cognito.
   *
   * @param {string} accessToken - The current valid access token for the user.
   * @param {string} previousPassword - The user's current password that needs to be changed.
   * @param {string} proposedPassword - The new password that the user wants to set.
   * @returns {Promise<void>} - A promise that resolves when the password change is successful.
   *                            Rejects if the password change fails.
   */
  public async changePassword(
    accessToken: string,
    previousPassword: string,
    proposedPassword: string
  ): Promise<void> {
    const cognitoISP = new AWS.CognitoIdentityServiceProvider();
    const params = {
      AccessToken: accessToken,
      PreviousPassword: previousPassword,
      ProposedPassword: proposedPassword,
    };

    try {
      await cognitoISP.changePassword(params).promise();
      console.log("Password changed successfully");
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  }

  public async initiatePasswordReset(username: string): Promise<void> {
    const userPool = new CognitoUserPool({
      UserPoolId: this.userPoolId,
      ClientId: this.clientId,
    });

    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => resolve(),
        onFailure: (err) =>
          reject(new Error(`[initiatePasswordReset]::${err.message}`)),
      });
    });
  }

  public async confirmPasswordReset(
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> {
    const userPool = new CognitoUserPool({
      UserPoolId: this.userPoolId,
      ClientId: this.clientId,
    });

    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => resolve(),
        onFailure: (err) =>
          reject(new Error(`[confirmPasswordReset]::${err.message}`)),
      });
    });
  }

  public async resendVerificationCode(username: string): Promise<void> {
    const userPool = new CognitoUserPool({
      UserPoolId: this.userPoolId,
      ClientId: this.clientId,
    });

    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(new Error(`[resendVerificationCode]::${err.message}`));
          return;
        }
        resolve(result);
      });
    });
  }

  /**
   * Logs the user out of AWS Cognito by invalidating the provided access token.
   *
   * @param {string} accessToken - The current valid access token for the user that will be used to log them out.
   * @returns {Promise<void>} - A promise that resolves when the logout process is complete.
   *                            Rejects if the logout fails.
   */
  public async logoutUser(accessToken: string): Promise<void> {
    const cognitoISP = new AWS.CognitoIdentityServiceProvider();
    const params = { AccessToken: accessToken };
    try {
      await cognitoISP.globalSignOut(params).promise();
      console.log("User logged out successfully.");
    } catch (error: any) {
      throw new Error(`[logoutUser]::${error.message}`);
    }
  }

  public async verifyToken(accessToken: string): Promise<boolean> {
    const cognitoISP = new AWS.CognitoIdentityServiceProvider();
    const params = { AccessToken: accessToken };

    try {
      await cognitoISP.getUser(params).promise();
      console.log("Token is valid");
      return true;
    } catch (error) {
      console.error("Token verification failed:", error);
      return false;
    }
  }

  public async refreshAccessTokenAndIsRefreshTokenExpired(
    refreshToken: string,
    username: string
  ): Promise<any> {
    const cognitoISP = new AWS.CognitoIdentityServiceProvider();
    const params = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: this.clientId,
      AuthParameters: { REFRESH_TOKEN: refreshToken },
    };

    try {
      const data = await cognitoISP.initiateAuth(params).promise();
      return data;
    } catch (error: any) {
      if (
        error.code === "NotAuthorizedException" &&
        error.message === "Refresh Token has expired"
      ) {
        console.error("Refresh token has expired");
      }
      throw new Error(`[refreshAccessToken]::${error.message}`);
    }
  }
}
