import {
  AddOutlined,
  ArrowBack,
  Edit,
  // PauseTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import { useState } from "react";
import CustomModal from "../CustomComponents/CustomModal";
import { commonBtnStyle } from "../../style";
// import { MYAIDA_API } from "../../API/AIDA_API";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../customHooks/AuthContext";
import { LoadingButton } from "@mui/lab";
import { calculateAge, formatPhoneNumber } from "../../Utils/utils";
import AppDrawer from "../../Layout/AppDrawer";
import PreviousSessionList from "./PreviousSessionList";
import { getData, postData } from "../../API/apiService";

const PatientDetails = () => {
  const location = useLocation();
  const { patientDetails } = location.state || {};

  const navigate = useNavigate();
  const { role } = useAuth();
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const [showSessionStartPrompt, setShowSessionStartPrompt] = useState(false);
  const [sessionBtnLoading, setSessionBtnLoading] = useState(false);

  const newSessionConfirm = async () => {
    try {
      if (
        practionerDetails &&
        practionerDetails.practionerInfo &&
        patientDetails
      ) {
        setSessionBtnLoading(true);
        const payload = {
          patient_id: patientDetails.id,
          practitioner_id: practionerDetails.practionerInfo.id,
          created_by: practionerDetails.practionerInfo.id,
          modified_by: practionerDetails.practionerInfo.id,
        };
        const SessionAPIResponse = await postData("/sessionStart", payload);
        const ChunkingTimingAPIResponse = await getData("/audioChunking");

        if (SessionAPIResponse && ChunkingTimingAPIResponse) {
          setSessionBtnLoading(false);
          setShowSessionStartPrompt(false);
          navigate("/new-session", {
            state: {
              sessionid: SessionAPIResponse.id,
              patientid: patientDetails.id,
              patientDetails: patientDetails,
              chunkingTiming:ChunkingTimingAPIResponse.value
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AppDrawer>
        <Paper
          variant="outlined"
          component="fieldset"
          sx={{
            backgroundColor: "#ffcc00",
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <strong>
                {patientDetails?.first_name ? patientDetails.first_name : "-"}{" "}
                {patientDetails?.last_name ? patientDetails.last_name : "-"}
              </strong>
            </Grid>
            {role !== "admin" && (
              <Grid item>
                <IconButton
                  sx={{
                    borderRadius: "50%", // Makes the button rounded
                    border: "1px solid black",
                    fontSize: "small",
                  }}
                  onClick={() => navigate(`/edit-patient/${patientDetails.id}`)}
                >
                  <Edit sx={{ fontSize: "small", color: "black" }} />
                </IconButton>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<ArrowBack />}
                  style={{
                    backgroundColor: "#ffcc00",
                    borderRadius: "05px",
                    border: "1px solid black",
                    color: "black",
                  }}
                  onClick={() => navigate("/my-patient")}
                >
                  Back
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<AddOutlined />}
                  style={{
                    backgroundColor: "#ffcc00",
                    borderRadius: "05px",
                    border: "1px solid black",
                    color: "black",
                  }}
                  onClick={() => setShowSessionStartPrompt(true)}
                >
                  New Session
                </Button>
              </Grid>
            )}
          </Grid>
          <Box height={10}></Box>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{ fontSize: "small" }}
          >
            <Grid item xs={2} sx={{ fontWeight: "bold" }}>
              Age
            </Grid>
            <Grid item xs={2}>
              {calculateAge(patientDetails?.dob && patientDetails.dob)}
            </Grid>
            <Grid item xs={2}>
              <span style={{ fontWeight: "bold" }}>Phone Number</span>
            </Grid>
            <Grid item xs={2}>
              {patientDetails?.phone_number
                ? formatPhoneNumber(patientDetails.phone_number)
                : "-"}
            </Grid>
          </Grid>
          <Box height={3}></Box>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{ fontSize: "small" }}
          >
            <Grid item xs={2} sx={{ fontWeight: "bold", fontSize: "small" }}>
              Date Of Birth
            </Grid>
            <Grid item xs={2}>
              {patientDetails?.dob && patientDetails.dob}
            </Grid>
            <Grid item xs={2}>
              <span style={{ fontWeight: "bold" }}>Email Address</span>
            </Grid>
            <Grid item xs={2}>
              {patientDetails?.email_address
                ? patientDetails.email_address
                : "-"}
            </Grid>
          </Grid>
          <Box height={3}></Box>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{ fontSize: "small" }}
          >
            <Grid item xs={2} sx={{ fontWeight: "bold", fontSize: "small" }}>
              Gender
            </Grid>
            <Grid item xs={2}>
              {patientDetails?.gender && patientDetails.gender === "GE_MA"
                ? "Male"
                : patientDetails?.gender && patientDetails.gender === "GE_FE"
                ? "Female"
                : "Other"}
            </Grid>

            <Grid item xs={2}>
              <span style={{ fontWeight: "bold" }}>Address</span>
            </Grid>
            <Grid item xs={2}>
              {patientDetails?.address && patientDetails.address}
            </Grid>
          </Grid>
        </Paper>
        <Box height={10}></Box>

        {patientDetails && patientDetails.id && (
          <PreviousSessionList patient_id={patientDetails.id} />
        )}

        <CustomModal
          open={showSessionStartPrompt}
          handleClose={() => setShowSessionStartPrompt(false)}
          size="small"
        >
          <Box
            sx={{
              borderLeft: "5px solid #ffcc00",
            }}
          >
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <img
                    src="/assets/images/Doctorpic2.png"
                    alt="Logo"
                    style={{
                      height: "15vh",
                      width: "20vh", // Adjust width as needed
                    }}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>Request Patient Consent Before Recording</Grid>
                    <Grid item>
                      <DialogActions>
                        <Button
                          onClick={() => setShowSessionStartPrompt(false)}
                          color="inherit"
                          variant="outlined"
                          size="small"
                          sx={{ color: "black", textTransform: "none" }}
                        >
                          Decline
                        </Button>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          onClick={newSessionConfirm}
                          autoFocus
                          size="small"
                          loading={sessionBtnLoading}
                          sx={commonBtnStyle}
                        >
                          Accept
                        </LoadingButton>
                      </DialogActions>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </CustomModal>
      </AppDrawer>
    </>
  );
};
export default PatientDetails;
