/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, MouseEvent } from "react";
import Avatar from "@mui/material/Avatar";
import { Divider, Grid } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import { KeyOutlined } from "@mui/icons-material";
import { useAuth } from "../customHooks/AuthContext";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";


const ProfileCard: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userDetails } = useAuth();
  const practitionerDetails = userDetails?.practionerInfo || {};

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {practitionerDetails && (
        <>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            onClick={handleClick}
            sx={{ cursor: "pointer" }}
          >
            <Grid item>
              <Grid container direction="column">
                <Grid item sx={{ color: "black", fontSize: "small" }}>
                  {practitionerDetails?.first_name || ""}-
                  {practitionerDetails?.last_name || ""}
                </Grid>
                {/* <Grid item sx={{ color: "#768789", fontSize: "x-small" }}>
                {practitionerDetails?.value || ""}
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item>
              <Avatar sizes="small" sx={{ height: "35px", width: "35px" }}>
                {practitionerDetails.first_name
                  ? practitionerDetails.first_name.charAt(0).toUpperCase()
                  : ""}
              </Avatar>
            </Grid>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              sx={{ padding: "10px", borderLeft: "5px solid #ffcc00" }}
            >
              <Grid item>
                <Avatar sizes="small" sx={{ height: "35px", width: "35px" }}>
                  {practitionerDetails.first_name
                    ? practitionerDetails.first_name.charAt(0).toUpperCase()
                    : ""}
                </Avatar>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item sx={{ color: "black", fontSize: "small" }}>
                    {practitionerDetails?.first_name || ""}-
                    {practitionerDetails?.last_name || ""}
                  </Grid>
                  <Grid
                    item
                    sx={{ color: "#768789", fontSize: "x-small" }}
                  ></Grid>
                  <Grid item sx={{ color: "#768789", fontSize: "xx-small" }}>
                    {userDetails?.email} | {practitionerDetails?.address}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider></Divider>
            <MenuItem
              onClick={() =>
                navigate(`/edit-practioner/${practitionerDetails.id}`)
              }
              sx={{ fontSize: "small" }}
            >
              <PersonIcon fontSize="small" sx={{ marginRight: 1 }} /> Edit
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => navigate(`/change-password`)}
              sx={{ fontSize: "small" }}
            >
              <KeyOutlined fontSize="small" sx={{ marginRight: 1 }} /> Change
              Password
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default ProfileCard;
