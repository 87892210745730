import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../customHooks/AuthContext";
import { Avatar, Box, CircularProgress, Grid } from "@mui/material";
import axios from "axios";

interface AudioPlayerProps {
  practionerdetails: Record<string, any>;
  patientdetails: Record<string, any>;
  sessionid: number;
}
const AudioPlayer: React.FC<AudioPlayerProps> = ({
  practionerdetails,
  sessionid,
  patientdetails,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const { adminPractitionerId, role } = useAuth();
  const [apiCallLoader, setApiCallLoader] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
    console.log("Audio is playing.");
  };

  const handlePause = () => {
    setIsPlaying(false);
    console.log("Audio is paused.");
  };

  useEffect(() => {
    getAudioUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAudioUrl = async () => {
    try {
      setApiCallLoader(true);
      const token = localStorage.getItem("accessToken");
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await axios.get(
          `${process.env.REACT_APP_FULL_AUDIO_URL}/${adminPractitionerId}/${patientdetails.id}/${sessionid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Example for Bearer token
            },
          }
        );
        if (APIResponse) {
          setAudioUrl(APIResponse.data.presigned_url);
          setApiCallLoader(false);
        }
      } else {
        const APIResponse = await axios.get(
          `${process.env.REACT_APP_FULL_AUDIO_URL}/${practionerdetails.practionerInfo.id}/${patientdetails.id}/${sessionid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Example for Bearer token
            },
          }
        );
        if (APIResponse) {
          setAudioUrl(APIResponse.data.presigned_url);
          setApiCallLoader(false);
        }
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      setApiCallLoader(false);
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Avatar
            sizes="small"
            sx={{
              height: "155px",
              width: "155px",
              // alignItems: "center",
              // backgroundColor: "#233f42",
              // fontSize: "72px",
              // border: "10px solid #efdf99",
            }}
          >
            <img src="/assets/images/Doctorpic2.png" alt="Logo" />
          </Avatar>
        </Grid>
      </Grid>
      <Box height={10}></Box>

      {!apiCallLoader && audioUrl && isPlaying && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img
              src="/assets/images/sound-8563.gif"
              alt="Logo"
              style={{
                height: "30vh",
                width: "60vh", // Adjust width as needed
              }}
            />
          </Grid>
        </Grid>
      )}
      {!apiCallLoader && audioUrl && !isPlaying && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img
              src="/assets/images/recording.jpg"
              alt="Logo"
              style={{
                height: "30vh",
                width: "60vh", //
              }}
            />
          </Grid>
        </Grid>
      )}
      <Box height={10}></Box>
      {!apiCallLoader && audioUrl && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <audio
              ref={audioRef}
              controls
              onPlay={handlePlay}
              onPause={handlePause}
            >
              <source src={audioUrl} type="audio/wav" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        </Grid>
      )}
      {apiCallLoader && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 3 }}
        >
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
};

export default AudioPlayer;
