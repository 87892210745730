import axiosInstance from "./axiosInstance";

export const getData = async (endpoint: string) => {
  try {
   
    const response = await axiosInstance.get(endpoint);
    return response.data;
  } catch (error:any) {
    throw error.data;
  }
};

export const postData = async (endpoint: string, data: object) => {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error:any) {
    throw error.data;
  }
};

export const putData = async (endpoint: string, data: object) => {
  try {
    const response = await axiosInstance.put(endpoint, data);
    return response.data;
  } catch (error:any) {
    throw error.data;
  }
};

export const patchData = async (endpoint: string, data: object) => {
  try {
    const response = await axiosInstance.patch(endpoint, data);
    return response.data;
  } catch (error:any) {
    throw error.data;
  }
};

export const deleteData = async (endpoint: string) => {
  try {
    const response = await axiosInstance.delete(endpoint);
    return response.data;
  } catch (error:any) {
    throw error.data;
  }
};
