export const commonBtnStyle = {
  backgroundColor: "#ffcc00",
  color: "black",
  fontWeight: "bold",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#ffcc00",
  },
};

export const StyledAPILoader = {
  display: 'block',
  margin: 'auto'
}