import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { commonBtnStyle } from "../../style";
import { useAuth } from "../../customHooks/AuthContext";
import { getData, postData } from "../../API/apiService";
import { toast } from "react-toastify";
import { serverErrorMessage } from "../../Utils/constant";

const SessionFeedback = ({ sessionid, reportid, feedbackExpanded }: any) => {
  const { adminPractitionerId, role } = useAuth();
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const [rating, setRating] = React.useState<number | null>(null);
  const [feedbackText, setFeedbackText] = React.useState<string>("");
  const [feedbackbtnloading, setFeedbackbtnloading] = React.useState(false);
  const [feedbackSubmitDisabled, setFeedbackSubmitDisabled] =
    React.useState(false);
  const [feedbackloading, setFeedbackLoading] = React.useState(false);

  useEffect(() => {
    getFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFeedback = async () => {
    try {
      setFeedbackLoading(true);
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/feedback/${adminPractitionerId}/${sessionid}/${reportid}`
        );
        setFeedbackValue(APIResponse);
      } else {
        const APIResponse = await getData(
          `/feedback/${practionerDetails.practionerInfo.id}/${sessionid}/${reportid}`
        );
        setFeedbackValue(APIResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setFeedbackValue = (APIResponse: any) => {
    if (Array.isArray(APIResponse) && APIResponse.length) {
      const result = APIResponse[APIResponse.length - 1];
      if(!result.length){
        setFeedbackSubmitDisabled(false);
      }
      setRating(result.ratings);
      setFeedbackText(result.feedback_text);
      setFeedbackLoading(false);
      setFeedbackSubmitDisabled(true);
      
    } else if (typeof APIResponse === 'object' && Object.keys(APIResponse).length > 0) {
      setRating(APIResponse.ratings);
      setFeedbackText(APIResponse.feedback_text);
      setFeedbackLoading(false);
      setFeedbackSubmitDisabled(true);
    } else {
      setFeedbackLoading(false);
      setRating(null);
      setFeedbackText("");
      setFeedbackSubmitDisabled(false);
    }
  };

  const handleFeedbackSubmit = async (report: string) => {
    try {
      setFeedbackbtnloading(true);

      const payload = {
        session_id: sessionid,
        reports_id: reportid,
        created_by: practionerDetails.practionerInfo.id,
        modified_by: practionerDetails.practionerInfo.id,
        feedback_url: "",
        ratings: rating,
        feedback_text: feedbackText,
      };

      const APIResponse = await postData(
        `/feedback/${practionerDetails.practionerInfo.id}`,
        payload
      );
      if (APIResponse) {
        toast.success("Feedback Submitted Successfully!");
        feedbackExpanded(reportid);
        setFeedbackbtnloading(false);
      }
    } catch (error: any) {
      console.log(error.message);
      setFeedbackbtnloading(false);
      toast.error(serverErrorMessage);
    }
  };
  return (
    <React.Fragment>
      {!feedbackloading && (
        <>
          <Box height={10}></Box>
          <Card>
            <CardContent>
              <Typography sx={{ fontWeight: "bold" }}>FeedBack</Typography>
              <Box height={10}></Box>
              <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <Button
                    key={num}
                    variant={rating === num ? "contained" : "outlined"}
                    onClick={() => setRating(num)}
                    style={{ margin: "0 5px" }}
                  >
                    {num}
                  </Button>
                ))}
                <Button
                  variant={rating === null ? "contained" : "outlined"}
                  onClick={() => setRating(null)}
                  style={{ marginLeft: "10px" }}
                >
                  N/A
                </Button>
              </Box>
              <TextField
                label="Feedback"
                name="feedback"
                type="text"
                size="small"
                multiline
                minRows={5}
                variant="outlined"
                fullWidth
                value={feedbackText}
                onChange={(event) => setFeedbackText(event.target.value)}
                sx={{ mb: 3 }}
              />
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "auto",
              }}
            >
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                onClick={() => feedbackExpanded(reportid)}
                sx={{ textTransform: "none", color: "black" }}
              >
                Cancel
              </Button>
              <LoadingButton
                size="small"
                color="primary"
                variant="contained"
                sx={commonBtnStyle}
                disabled={feedbackSubmitDisabled}
                loading={feedbackbtnloading}
                onClick={() => handleFeedbackSubmit("session_summary")}
              >
                Submit
              </LoadingButton>
            </CardActions>
          </Card>
        </>
      )}
      {feedbackloading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 3 }}
        >
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
};
export default SessionFeedback;
