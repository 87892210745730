import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CircularProgressLoader from "./Layout/Loader";
import { useAuth } from "./customHooks/AuthContext";
import SessionReport from "./Views/SessionReports/SessionReport";
import PatientDetails from "./Views/MyPatients/PatientDetails";
import NewSessionRecord from "./Views/Session/NewSessionRecord";
import PrevSessionDetails from "./Views/Session/PrevSessionDetails";
import AIDASettings from "./Views/Admin/Settings/AIDASettings";
import SessionAudio from "./Views/Session/SessionAudio";

// Lazy load your component
const SignIn = React.lazy(() => import("./Auth/Signin"));
const SignUp = React.lazy(() => import("./Auth/SignUp"));
const Dashboard = React.lazy(() => import("./Views/Dashboard/index"));
// const Sessions = React.lazy(() => import("./Views/Sessions/Sessions"));
// const AddRecord = React.lazy(() => import("./Views/Sessions/AddRecord"));
const Feedback = React.lazy(() => import("./Views/Feedback/Feedback"));
const ForgotPassword = React.lazy(() => import("./Auth/ForgotPassword"));
const MyPatient = React.lazy(() => import("./Views/MyPatients/MyPatientList"));
// const NewSession = React.lazy(() => import("./Views/MyPatients/NewSessionOld"));
// const SessionFeedback = React.lazy(
//   () => import("./Views/MyPatients/SessionFeedback")
// );
// const ServiceRequest = React.lazy(() => import("./Views/ServiceRequest/index"));
const AddPatient = React.lazy(() => import("./Views/MyPatients/AddPatient"));
const EditPatient = React.lazy(() => import("./Views/MyPatients/EditPatient"));
const EditPractioner = React.lazy(
  () => import("./Views/Prationer/EditPractioner")
);
const ChangePassword = React.lazy(() => import("./Auth/ChangePassword"));
const MyPractitionerList = React.lazy(
  () => import("./Views/Admin/Practitioner/PractitionerList")
);
const FeedbackList = React.lazy(
  () => import("./Views/Admin/Feedback/FeedbackList")
);

export function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Suspense fallback={<CircularProgressLoader />}>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />

          <Route
            path="/dashboard"
            element={
              isAuthenticated ? <Dashboard /> : <Navigate to="/signin" />
            }
          />
          <Route
            path="/my-patient"
            element={
              isAuthenticated ? <MyPatient /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/patient-details"
            element={
              isAuthenticated ? <PatientDetails /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/new-session"
            element={
              isAuthenticated ? <NewSessionRecord /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/session-report"
            element={
              isAuthenticated ? <SessionReport /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/session-audio"
            element={
              isAuthenticated ? <SessionAudio /> : <Navigate to="/signin" />
            }
          />
          <Route
            path="/session-details"
            element={
              isAuthenticated ? (
                <PrevSessionDetails />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />

          <Route
            path="/feedback"
            element={isAuthenticated ? <Feedback /> : <Navigate to="/signin" />}
          />

          <Route
            path="/add-patient"
            element={
              isAuthenticated ? <AddPatient /> : <Navigate to="/signin" />
            }
          />
          <Route
            path="/edit-patient/:id"
            element={
              isAuthenticated ? <EditPatient /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/edit-practioner/:id"
            element={
              isAuthenticated ? <EditPractioner /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/change-password"
            element={
              isAuthenticated ? <ChangePassword /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/admin/practitoner-list"
            element={
              isAuthenticated ? (
                <MyPractitionerList />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />

          <Route
            path="/admin/feedback-list"
            element={
              isAuthenticated ? <FeedbackList /> : <Navigate to="/signin" />
            }
          />

          <Route
            path="/admin/settings"
            element={
              isAuthenticated ? <AIDASettings /> : <Navigate to="/signin" />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
