import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";

import AppDrawer from "../../Layout/AppDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { findDuration, splitDateTime } from "../../Utils/utils";
import { getData } from "../../API/apiService";
import { useAuth } from "../../customHooks/AuthContext";
import AudioPlayer from "./AudioPlayer";

export const sessionReportTitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#fff6d1",
  width: "100%",
  padding: "0.5em",
};

const SessionAudio = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { adminPractitionerId, role } = useAuth();
  const { patientDetails, sessionid } = location.state || {};
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};

  const [duration, setDuration] = useState<string>("");

  const [sessionDate, setSessionDate] = useState("-");
  const [sesssionTime, setSessionTime] = useState("-");

  useEffect(() => {
    getSessionDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessionDetails = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/session/${adminPractitionerId}/${sessionid}`
        );
        if (APIResponse) {
          const result = splitDateTime(APIResponse.audio_created_at);
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      } else {
        const APIResponse = await getData(
          `/session/${practionerDetails.practionerInfo.id}/${sessionid}`
        );
        if (APIResponse) {
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          const result = splitDateTime(APIResponse.audio_created_at);
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
    }
  };

  const handleBack = () => {
    navigate("/patient-details", {
      state: { patientDetails: patientDetails },
    });
  };

  return (
    <AppDrawer>
      <Paper
        variant="outlined"
        component="fieldset"
        sx={{
          backgroundColor: "#ffcc00",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 3, fontSize: "medium" }}
            >
              {patientDetails?.first_name ? patientDetails.first_name : "-"}{" "}
              {patientDetails?.last_name ? patientDetails.last_name : "-"}
              <Divider
                sx={{ backgroundColor: "#ffcc00", borderBottomWidth: 2 }}
              ></Divider>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              startIcon={<ArrowBack />}
              style={{
                backgroundColor: "#ffcc00",
                borderRadius: "05px",
                border: "1px solid black",
                color: "black",
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Box height={10}></Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          sx={{ fontWeight: "bold", fontSize: "small" }}
        >
          <Grid item xs={3}>
            Initial Consultation Date
          </Grid>
          <Grid item xs={3}>
            Appointment Time
          </Grid>

          <Grid item xs={2}>
            Appointment Date
          </Grid>
          <Grid item xs={2}>
            Duration
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sesssionTime}
          </Grid>

          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {duration} Min
          </Grid>
        </Grid>
      </Paper>
      <Box height={10}></Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Session Audio
      </Typography>
      <AudioPlayer
        practionerdetails={practionerDetails}
        sessionid={sessionid}
        patientdetails={patientDetails}
      />
    </AppDrawer>
  );
};

export default SessionAudio;
