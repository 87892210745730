import {
  // SmsOutlined, PaddingOutlined, InsertChartOutlinedOutlined
  // GridViewOutlined,
  GroupOutlined,
  Settings,
  // ThumbUpOffAlt,
  // ForumOutlined
} from "@mui/icons-material";

export const menuListItems = [
  // { text: "Dashboard", link: "/dashboard", icon: <GridViewOutlined />,subItems:[] },
  {
    text: "My Patient",
    link: "/my-patient",
    icon: <GroupOutlined />,
    subItems: [],
  },
  // {
  //   text: "Service Request",
  //   link: "/services_request",
  //   icon: <AltRouteOutlined />,
  //   subItems:[]
  // },
  // {
  //   text: "Feedback",
  //   link: "/feedback",
  //   icon: <ForumOutlined />,
  //   subItems:[]
  // }
  // ,
  // {
  //   text: "Sessions",
  //   link: "/sessions",
  //   icon: <PaddingOutlined />,
  //   subItems:[]
  // },
];

export const AdminMenuListItem = [
  {
    text: "Practitioners",
    link: "/admin/practitoner-list",
    icon: <GroupOutlined />,
    subItems: [],
  },
  // ,
  // {
  //   text: "Feedback",
  //   link: "/admin/feedback-list",
  //   icon: <ThumbUpOffAlt />,
  //   subItems: [],
  // },

   
  {
    text: "Settings",
    link: "/admin/settings",
    icon: <Settings />,
    subItems: [],
  },
];
