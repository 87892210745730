import { ContentPasteOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  styled,
  Grid,
  IconButton,
  Paper,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  tableCellClasses,
  CircularProgress,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StyledTable, StyledTableContainer } from "./style";
import {} from "@mui/material";
import { splitDateTime } from "../../Utils/utils";
import CustomModal from "../CustomComponents/CustomModal";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteData, getData } from "../../API/apiService";
import { serverErrorMessage } from "../../Utils/constant";
import { useAuth } from "../../customHooks/AuthContext";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#eceff3",
    color: "black",
    padding: "10px",
    // maxWidth: "65px",

    wordBreak: "unset",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    // maxWidth: "65px",

    // paddingLeft: '10px',
    // paddingRight: '10px',
    padding: "10px",
    // borderRight: '1px solid rgba(224, 224, 224, 1)',
    wordBreak: "unset",
    // backgroundColor: '#f6f8fa',
    whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // paddingLeft: '10px',
  // verticalAlign: 'baseline',
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& th": {
    // wordBreak: 'unset'
  },
  "&:hover": {
    border: 0,
    backgroundColor: "#add8e687",
    cursor: "pointer",
  },
}));
const PreviousSessionList = ({ patient_id }: Record<string, any>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {  adminPractitionerId, role } = useAuth();
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const [isServerError, setIsServerError] = useState<boolean>(false);
  const { patientDetails } = location.state || {};
  const [apiCallLoader, setApiCallLoader] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const [showSessionDeletePrompt, setShowSessionDeletePrompt] = useState(false);
  const [isDeletedSessionId, setisDeletedSessionId] = useState<
    number | undefined
  >(undefined);
  const [isDeleteSessionBtnLoading, setIsDeleteSessionBtnLoading] =
    useState<boolean>(false);

  useEffect(() => {
    getSessionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessionList = async () => {
    try {
      setApiCallLoader(true);
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `patient/allSessions/${adminPractitionerId}/${patient_id}`
        );
        if (APIResponse) {
          setSessionList(APIResponse.reverse());
          setApiCallLoader(false);
        }
      } else if (practionerDetails && practionerDetails.practionerInfo) {
        const practionerId = practionerDetails.practionerInfo.id;
        if (!practionerId) {
          throw new Error(serverErrorMessage);
        }
        const APIResponse = await getData(
          `patient/allSessions/${practionerId}/${patient_id}`
        );
        if (APIResponse) {
          setSessionList(APIResponse.reverse());
          setApiCallLoader(false);
        }
      }else{
        throw new Error(serverErrorMessage);
      }
    } catch (error) {
      console.log(error);
      setApiCallLoader(false);
      setIsServerError(true);
    }
  };

  const handleSessionDelete = async () => {
    try {
      if (isDeletedSessionId) {
        setIsDeleteSessionBtnLoading(true);
        const APIResponse = await deleteData(
          `/session?id=${isDeletedSessionId}`
        );
        if (APIResponse) {
          setShowSessionDeletePrompt(false);
          toast.success("Session Deleted Successfully!");
          setIsDeleteSessionBtnLoading(false);
          getSessionList();
        }
      }
    } catch (error: any) {
      console.log(error);
      setIsDeleteSessionBtnLoading(false);
      toast.error(error.message);
    }
  };

  const handlePrevSessionRowClick = (data: any) => {
    navigate("/session-details", {
      state: {
        patientDetails: patientDetails,
        sessionDetails: data,
      },
    });
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          padding: "10px",
          borderRadius: "10px",
          minHeight: "380px",
          maxHeight: "300px",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            padding: "20px",
            fontSize: "small",
            borderRadius: "10px", // Adjust the border radius as needed
            display: "flex",

            gap: "10px", // Adjust the gap between icon and text as needed
          }}
        >
          <IconButton
            sx={{
              borderRadius: "50%", // Makes the button rounded
              // border: "1px solid black",
              backgroundColor: "#ffcc00",
              fontSize: "small",
              cursor: "default",
              "&:hover": {
                backgroundColor: "#ffcc00", // Keeps the background color the same on hover
              },
            }}
          >
            <ContentPasteOutlined sx={{ fontSize: "medium", color: "black" }} />
          </IconButton>

          <Typography sx={{ fontWeight: "bold" }}>Previous Sessions</Typography>
        </div>

        <Box width="100%">
          <StyledTableContainer>
            <StyledTable style={{ tableLayout: "auto" }}>
              <TableHead>
                <TableRow key={"tableHead"}>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">
                    Appointment Time
                  </StyledTableCell>
                  <StyledTableCell align="left">AIDA Diagnosis</StyledTableCell>
                  {/* <StyledTableCell align="left">Recording</StyledTableCell> */}
                  <StyledTableCell align="left">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!apiCallLoader &&
                  sessionList.map((item: any, index) => {
                    const { date, time } = splitDateTime(
                      item.audio_created_at
                    ) as never;
                    return (
                      <StyledTableRow
                        key={index}
                        onClick={() => handlePrevSessionRowClick(item)}
                      >
                        <StyledTableCell align="left">
                          {date ? date : ""}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {time ? time : ""}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {/* <Paper
                                variant="outlined"
                                sx={{
                                  display: "inline-block",
                                  padding: "05px",
                                  borderRadius: 10,
                                  backgroundColor: "#f0f1f5",
                                  textAlign: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ color: "black" }}
                                >
                                 
                                </Typography>
                              </Paper> */}
                          {item.AIDA_diagnosis ? item.AIDA_diagnosis : ""}
                        </StyledTableCell>
                        
                        <StyledTableCell>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={(event) => {
                              event.stopPropagation();
                              setShowSessionDeletePrompt(true);
                              setisDeletedSessionId(item.id);
                            }}
                          >
                            Delete
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                {!apiCallLoader &&
                  !isServerError &&
                  sessionList &&
                  !sessionList.length && (
                    <StyledTableRow key={"emptyRow"}>
                      <StyledTableCell
                        align="left"
                        colSpan={5}
                        sx={{ textAlign: "center" }}
                      >
                        No Session Found
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                {isServerError && (
                  <StyledTableRow key={"emptyRow"}>
                    <StyledTableCell
                      align="left"
                      colSpan={5}
                      sx={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {serverErrorMessage}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>

          {apiCallLoader && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Paper>

      {/* Session Delete Modal */}
      <CustomModal
        open={showSessionDeletePrompt}
        handleClose={() => setShowSessionDeletePrompt(false)}
        size="small"
      >
        <Box
          sx={{
            borderLeft: "5px solid #ffcc00",
          }}
        >
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <img
                  src="/assets/images/Doctorpic2.png"
                  alt="Logo"
                  style={{
                    height: "15vh",
                    width: "20vh", // Adjust width as needed
                  }}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>Are you sure you want to delete session?</Grid>
                  <Grid item>
                    <DialogActions>
                      <Button
                        onClick={() => setShowSessionDeletePrompt(false)}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        sx={{ color: "black", textTransform: "none" }}
                      >
                        No
                      </Button>
                      <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={handleSessionDelete}
                        autoFocus
                        size="small"
                        loading={isDeleteSessionBtnLoading}
                      >
                        Yes
                      </LoadingButton>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </CustomModal>
    </>
  );
};
export default PreviousSessionList;
